var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('ais-sort-by',{attrs:{"items":[
      { value: 'class', label: 'Default' },
      { value: 'instant_search_point_asc', label: 'Points' } ]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var items = ref.items;
    var refine = ref.refine;
return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-sort")]),_vm._v(" Sort By ")],1)]}}])},[_c('v-list',_vm._l((items),function(item){return _c('v-list-item',{key:item.value,attrs:{"value":item.value},on:{"click":function($event){$event.preventDefault();return refine(item.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.label))])],1)}),1)],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }