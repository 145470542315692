var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.clas)?_c('v-card',{staticClass:"mx-auto mb-4",attrs:{"max-width":"calc(100% - 1.6em)","outlined":"","ripple":false}},[_c('MapImage',{attrs:{"classID":_vm.clas.id}}),_c('v-responsive',{on:{"click":function($event){return _vm.$router.push({
        name: 'ClassDetails',
        params: {
          classID: _vm.clas.id,
          selectedTime: _vm.upcomingClass.startTime,
        },
      })}}},[_c('v-list-item',[_c('div',{staticStyle:{"text-align":"left"}},[_c('v-card-title',{staticClass:"headline pl-0"},[_vm._v(" "+_vm._s(_vm.clas.name)+" ")]),_c('v-list-item-subtitle',{staticStyle:{"font-weight":"bold"}},[(_vm.moment().isSame(_vm.dateObject, 'day'))?_c('span',[_vm._v(" Today, ")]):(
              _vm.moment()
                .add(_vm.moment.duration(1, 'd'))
                .isSame(
                  _vm.dateObject.clone().add(_vm.moment.duration(1, 'd')),
                  'day'
                )
            )?_c('span',[_vm._v(" Tomorrow, ")]):_c('span',[_vm._v(" "+_vm._s(_vm.dateObject.format("dddd, "))+" ")]),_vm._v(" "+_vm._s(_vm.dateObject.format("D MMM"))+" "),(_vm.moment().year() !== _vm.dateObject.year())?_c('span',[_vm._v(" "+_vm._s(_vm.dateObject.format("YYYY"))+" ")]):_vm._e(),_c('br'),_vm._v(" "+_vm._s(_vm.dateObject.format("h:mm a"))+" - "+_vm._s(_vm.moment .unix(_vm.upcomingClass.startTime) .add(_vm.clas.length, "minutes") .format("h:mm a"))+" ")]),_c('br'),(_vm.partner)?_c('v-list-item-subtitle',[_c('div',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.partner.name)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.clas.location_address || _vm.partner.location_address)+" ")])]):_vm._e()],1)])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-share-variant")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleFavouriteClass(_vm.clas.id)}}},[(_vm.isFavourite)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-heart ")]):_c('v-icon',[_vm._v("mdi-heart-outline")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-calendar-today")])],1),_c('div',[_vm._v(_vm._s(_vm.upcomingClass.points))])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }